import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import { removePlaceholder } from "../components/helperFunctions"

// Function checks if each logo has a weight.  If not, it sets weight to default value of 99
const checkWeight = sponsorArray => {
  const weightedArray = sponsorArray.map(sponsorObject => {
    const weight = !!sponsorObject.node.weight ? sponsorObject.node.weight : 99
    sponsorObject.node.weight = weight
    return sponsorObject
  })
  return weightedArray
  // sortArraybyWeight(weightedArray)
}

// Sorts the sponsor logo object array by weight (ascending order)
// reference: https://www.w3schools.com/js/js_array_sort.asp
const sortArraybyWeight = sponsorArray => {
  sponsorArray.sort((a, b) => a.node.weight - b.node.weight)
  // console.log("sponsorArray:", sponsorArray)
  return sponsorArray
}

const Sponsors = () => (
  <StaticQuery
    query={graphql`
      query SponsorQuery {
        sponsorLogos: allContentfulSponsorLogo {
          edges {
            node {
              title
              weight
              url
              logo {
                id
                fixed(width: 250) {
                  ...GatsbyContentfulFixed
                }
              }
              placeholder
            }
          }
        }
      }
    `}
    render={data => {
      // Array of data from gql query
      const sponsorLogos = data.sponsorLogos.edges
      // Define new array of data, minus placeholder content
      const sponsorLogosFiltered = removePlaceholder(sponsorLogos)
      const sponsorLogosSorted = sortArraybyWeight(
        checkWeight(sponsorLogosFiltered)
      )

      return (
        <div className="sponsor">
          {sponsorLogosSorted.map((logoObject, index) => {
            let { logo, url } = logoObject.node

            /* if url not provided, make it link to top of current page (#) */
            url = !!url ? url : "#"

            const logoLink =
              url !== "#" ? (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Img className="sponsor__logoImage" fixed={logo.fixed} />
                </a>
              ) : (
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Img
                    className="sponsor__logoImage"
                    fixed={logo.fixed}
                    key={index}
                  />
                </a>
              )

            return logoLink
          })}
        </div>
      )
    }}
  ></StaticQuery>
)

export default Sponsors
