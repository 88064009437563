import React from "react"
import { AnimatePresence, motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"

const TRANSITION_TIME_S = 1

const ImageHolder = ({ image, className = "" }) => {
  const backgroundFluidImageStack = [
    image.fluid,
    `linear-gradient(0deg, rgba(0,0,0,0.45) , rgba(0,0,0,0.45) )`,
  ].reverse()

  return (
    <AnimatePresence initial={false}>
      <motion.div
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: TRANSITION_TIME_S }}
        key={`${image.id}`}
        className={` absolute  w-full h-96  shadow-lg   ${className}`}
        // style={{ backgroundImage: `url(${image.publicURL})` }}
      >
        <BackgroundImage
          fluid={backgroundFluidImageStack}
          className="topImage  bg-white h-full "
          style={{ backgroundSize: "cover" }}
        >
          <div className=" h-full flex flex-col justify-end px-4 py-10">
            <div className="topImage__title text-secondary text-6xl font-bold">
              Sioux Lookout
            </div>
            <div className="topImage__divider" />
            <div className="topImage__subtitle text-3xl md:text-4xl">
              Minor Hockey Association
            </div>
          </div>
        </BackgroundImage>
      </motion.div>
    </AnimatePresence>
  )
}

export default ImageHolder
