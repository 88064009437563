import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import { useStaticQuery } from "gatsby"

import { useCycle } from "framer-motion"
import ImageHolder from "./Hero/ImageHolder"

const HeroImage = ({ topImage }) => (
  <BackgroundImage
    fluid={topImage.childImageSharp.fluid}
    alt="Sample Photo"
    className="topImage"
  >
    <div className="topImage__textContainer">
      <div className="topImage__title">Sioux Lookout</div>
      <div className="topImage__divider" />
      <div className="topImage__subtitle">Minor Hockey Association</div>
    </div>
  </BackgroundImage>
)

// TO USE
// https://www.npmjs.com/package/react-slideshow-image

export const HeroImages = () => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      contentfulFrontPage {
        heroImages {
          id
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const contentfulImages = data.contentfulFrontPage.heroImages

  const [currentScene, setCurrentScene] = useCycle(...contentfulImages)
  const TRANSITION_TIME = 10000

  useEffect(() => {
    const timeOut = setTimeout(setCurrentScene, TRANSITION_TIME)
    return () => clearTimeout(timeOut)
  }, [currentScene, setCurrentScene])

  const variants = {
    open: { opacity: 1 },
    closed: { opacity: 0 },
  }

  // SOLUTION TO ANIMATE BANNER:
  // https://rangle.io/blog/how-to-create-an-animated-hero-banner-in-react-using-framer-motion/

  // also helpful: https://codesandbox.io/s/framer-motion-issue-forked-6no3o?file=/src/App.js:613-623
  //https://czaplinski.io/blog/super-easy-animation-with-react-hooks/
  // For later - to add settimeout to rotate the images: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks

  return (
    <div className=" w-full h-96 relative shadow-lg overflow-hidden rounded-b">
      <ImageHolder image={currentScene} />
    </div>
  )
}

export default HeroImage
